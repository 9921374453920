import React, { useState, useEffect } from "react";
import "./css/videoTab.css";
import axios from "axios";
import Footer from "../mainComponent/js/footer";
import ArtVideoComponent from "./artComponent/js/artComponentVideo";
import { Link } from "react-router-dom";
import {BASE_URL} from '../../api/URL'

import LoadingPage from "../loadingComponent/js/loadingPage";
import Modal from "../modal/js/modal";

function App() {
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false);
    }, 2000);
    return () =>{
      // console.log("LoadingPage down");
    }
   },[]);
  const [list, setList] = useState([]);
  useEffect(() => {
    settingData();
  }, []);
  const settingData = async () => {
    const { data } = await axios({
      method: "get",
      url: `${BASE_URL}/visualList`,
    });

    setList(data);
  };

  const setVideoList = () => {
    //Single component
    const listTmp = list.map((data, index) => (
      <div key={data.krName}>
        <Link
          to={"/work_personal"}
          state={{
            engName: data.engName,
            partName: "visual/video",
            studyNum: data.num
          }}
        >
          <ArtVideoComponent
            productName={data.productName}
            krName={data.krName}
            engName={data.engName}
            partName={"영상"}
          />
        </Link>
      </div>
    ));
    return <div id="video-artComponentVideo-component">{listTmp}</div>;
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  
  return (
    <div className="video-main-div">
      <Modal open={modalOpen} close={closeModal} header="안내사항">
        <img src="assets/img/popup.png" alt="" style={{width:'100%'}}/>
      </Modal>
      {loading ? <LoadingPage/> : null}
      <div style={{ paddingBottom: "0%" }}>
      <div className="row" id="head">
        <div className="col-8" id="video-head-title-line">
          <div className="justify-content-end">
            <p id="video-head-title">VISUAL</p>
          </div>
        </div>
        <div className="col-4" id="video-head-title-sub-container">
          <p id="video-head-title-sub">영상 디자인</p>
        </div>
      </div>
      </div>
      {/* <div id="video-artComponentVideo-container">{setVideoList()}</div> */}
      <div>{setVideoList()}</div>
      <Footer />
    </div>
  );
}

export default App;
