import React from "react";
import "../css/loadingPage.css";

function App() {
  return (
    <div id="loading-main-div">
      <div style={{margin: 'auto'}}>
        <div>
          <video src="assets/video/loading.mp4" muted autoPlay loop />
        </div>
        <div>
          <img src="assets/img/logo_black.png" alt="" style={{width:'50%'}}/>
        </div>
      </div>
    </div>
  );
}

export default App;
