import React from "react";
import { Link } from "react-router-dom";
import '../css/baesuhyeonComponent.css';

 function App() {
    return (
      <div id='baesuhyeon-main-div'>
        <img id="baesuhyeon-img" alt="" src="assets/img/personal/visual/video/BaeSuHyeon/Bae-Su-Hyeon_1.png"/>
        <video id="baesuhyeon-video" style={{paddingTop:"5%"}} src="assets/video/personal/visual/BaeSuHyeon/baesuhyeon_1.mp4" controls/>
        <div style={{width:'100%'}}>
          <div id="baesuhyeon-img-div">
            <div><img id="baesuhyeon-gif" alt="" style={{paddingTop:"65%"}} src="assets/img/personal/visual/video/BaeSuHyeon/gif/Bae-Su-Hyeon_1.gif"/></div>
            <div><img id="baesuhyeon-gif" alt="" style={{paddingTop:"5%"}} src="assets/img/personal/visual/video/BaeSuHyeon/gif/Bae-Su-Hyeon_2.gif"/></div>
            <div><img id="baesuhyeon-gif" alt="" style={{paddingTop:"5%"}} src="assets/img/personal/visual/video/BaeSuHyeon/gif/Bae-Su-Hyeon_3.gif"/></div>
            <div><img id="baesuhyeon-gif" alt="" style={{paddingTop:"5%"}} src="assets/img/personal/visual/video/BaeSuHyeon/gif/Bae-Su-Hyeon_4.gif"/></div>
            <div><img id="baesuhyeon-gif" alt="" style={{paddingTop:"5%"}} src="assets/img/personal/visual/video/BaeSuHyeon/gif/Bae-Su-Hyeon_5.gif"/></div>
          </div>
        </div>
        
        <div id="personal-profile-div">
            <Link to={'/design_personal'} state={{
              engName: 'Bae_Su_Hyeon',
              partName: 'visual/visual',
              studyNum: '20184094',
            }}>
                <img alt=""  src="assets/img/personal/profile/20184094.png" style={{width:'30%'}}/>'
            </Link>
        </div>
      </div>
    );
  }
  
  export default App;
  