import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../css/commonComponent.css";
import { Link } from "react-router-dom";

import KimyeonghaComponetPage from "../../visual/js/kimyeonghaComponet";
import MoonBoEunComponentPage from "../../visual/js/moonboeunComponent";
import SimSeongHyeonComponentPage from "../../visual/js/simseonghyeonComponent";
import LeeYuRimComponentPage from "../../visual/js/leeyurimComponent";
import BaeSuHyeonComponentPage from "../../visual/js/baesuhyeonComponent";
import ShinYeRinComponentPage from "../../visual/js/shinyerinComponent";
import KimjoEeComponentPage from "../../visual/js/KimJoAeComponent";
import ParkjinHyeongComponentPage from "../../visual/js/ParkJinHyeongComponent";
import LeeSeonHwaComponentPage from "../../visual/js/LeeSeonHwaComponent";
import LeeYuJinComponentPage from '../../visual/js/LeeYuJinComponent';
import KimhyukjinComponentPage from "../../visual/js/KimHyukJinComponent";
import ParkseongWookComponentPage from "../../visual/js/ParkSeongWookComponent";
import ShindayoungComponentPage from "../../visual/js/ShinDaYoungComponent";
import LeeJuhyeonComponentPage from "../../visual/js/LeeJuHyeonComponent";
import ChoihayoungComponentPage from "../../visual/js/ChoiHaYoungComponent";
import HongsunkyungComponentPage from "../../visual/js/HongSunKyungComponent";
import VisualDefaultComponentPage from "../../visual/js/visualDefaultComponent";

import KimChaeWonComponentPage from "../../product/js/KimChaeWonComponent";
import KimDaHyeComponentPage from "../../product/js/KimDaHyeComponent";
import KimJooYoungComponentPage from "../../product/js/KimJooYoungComponent";
import KimJiSooComponentPage from "../../product/js/KimJiSooComponent";
import ChoiGiWonComponentPage from "../../product/js/ChoiGiWonComponent";
import KimChaeEunComponentPage from "../../product/js/KimChaeEunComponent";
import KimHeeYeonComponentPage from "../../product/js/KimHeeYeonComponent";
import MoonJuRyeongComponentPage from "../../product/js/MoonJuRyeongComponent";
import ParkSuMinComponentPage from "../../product/js/ParkSuMinComponent";
import LeeSeonHyeonComponentPage from "../../product/js/LeeSeonHyeonComponent";
import HaDoGyeomComponentPage from "../../product/js/HaDoGyeomComponent";

function App(props) {
  const location = useLocation();
  const engName = location.state?.engName;
  const partName = location.state?.partName;
  const profileName = location.state?.engName.replaceAll("_", "-");
  const studyNum = location.state?.studyNum;
  const imgLink = `assets/img/personal/${partName}/${profileName}.png`;
  const profileImgLink = `assets/img/personal/profile/${studyNum}.png`;

  useEffect(() => {
    console.log(location.state?.engName);
    console.log(location.state?.studyNum);
  });
  const viewComponents = (el) => {
    const name = el.engName;
    const num = location.state?.studyNum;
    console.log(num);
    const tmpPart = el.partName.split('/');
    const part = tmpPart[tmpPart.length-1];
    if(num === 20184094){
      if(part === 'video'){
        return (
          <BaeSuHyeonComponentPage/>
        )
      }
      else{
        return (
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Bae-Su-Hyeon.png'}/>
        )
      }
    }
    else if(num === 20184113){
      if(part === 'video'){
        return (
          <ChoihayoungComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Choi-Ha-Young.png'}/>
        )
      }
    }
    else if(num === 20184115){
      if(part === 'video'){
        return (
          <HongsunkyungComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Hong-Sun-Kyung.png'}/>
        )
      }
      
    }
    else if(num === 20154090){
      if(part === 'video'){
        return (
          <KimhyukjinComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Kim-Hyuk-Jin.png'}/>
        )
      }
      
    }
    else if(num === 20184079){
      if(part === 'video'){
        return (
          <KimjoEeComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Kim-Jo-Ae.png'}/>
        )
      }
      
    }
    else if(num === 20184078){
      if(part === 'video'){
        return (
          <KimyeonghaComponetPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Kim-Yeong-Ha.png'}/>
        )
      }
      
    }
    else if(num === 20164102){
      if(part === 'video'){
        return (
          <LeeJuhyeonComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Lee-Ju-Hyeon.png'}/>
        )
      }
      
    }
    else if(num === 20184101){
      if(part === 'video'){
        return (
          <LeeSeonHwaComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Lee-Seon-Hwa.png'}/>
        )
      }
      
    }
    else if(num === 20194108){
      if(part === 'video'){
        return (
          <LeeYuJinComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Lee-Yu-Jin.png'}/>
        )
      }
      
    }
    else if(num === 20184103){
      if(part === 'video'){
        return (
          <LeeYuRimComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Lee-Yu-Rim.png'}/>
        )
      }
      
    }
    else if(num === 20184087){
      if(part === 'video'){
        return (
          <MoonBoEunComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Moon-Bo-Eun.png'}/>
        )
      }
      
    }
    else if(num === 20184092){
      if(part === 'video'){
        return (
          <ParkjinHyeongComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Park-Jin-Hyeong.png'}/>
        )
      }
      
    }
    else if(num === 20174081){
      if(part === 'video'){
        return (
          <ParkseongWookComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Park-Seong-Wook.png'}/>
        )
      }
      
    }
    else if(num === 20174086){
      if(part === 'video'){
        return (
          <ShindayoungComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Shin-Da-Young.png'}/>
        )
      }
      
    }
    else if(num === 20184099){
      if(part === 'video'){
        return (
          <ShinYeRinComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Shin-Ye-Rin.png'}/>
        )
      }
      
    }
    else if(num === 20214083){
      if(part === 'video'){
        return (
          <SimSeongHyeonComponentPage/>
        )
      }
      else{
        return(
          <VisualDefaultComponentPage partName={part} personName={engName} studyNum={studyNum} profileName={'Sim-Seong-Hyeon.png'}/>
        )
      }
      
    }
    else if(num === 20184081){
      return (
        <KimChaeWonComponentPage/>
      )
    }
    else if(num === 20184080){
      return (
        <KimJiSooComponentPage/>
      )
    }
    else if(num === 20184076){
      return (
        <KimDaHyeComponentPage/>
      )
    }
    else if(num === 20174074){
      return (
        <KimJooYoungComponentPage/>
      )
    }
    else if(num === 20184111){
      return (
        <ChoiGiWonComponentPage/>
      )
    }
    else if(num === 20194095){
      return (
        <KimChaeEunComponentPage/>
      )
    }
    else if(num === 20214075){
      return (
        <KimHeeYeonComponentPage/>
      )
    }
    else if(num === 20184089){
      return (
        <MoonJuRyeongComponentPage/>
      )
    }
    else if(num === 20174082){
      return (
        <ParkSuMinComponentPage/>
      )
    }
    else if(num === 20214086){
      return (
        <LeeSeonHyeonComponentPage/>
      )
    }
    else if(num === 20174104){
      return (
        <HaDoGyeomComponentPage/>
      )
    }
    else{
      return(
      <div id="common-main-div">
        <div>
          <img id="common-img-div" alt="" src={imgLink} />
        </div>
        <div>
          <Link
            to={"/design_personal"}
            state={{
              engName: location.state?.engName,
              partName: location.state?.partName,
              studyNum: studyNum,
            }}
          >
            <img src={profileImgLink} alt="" style={{width:'30%'}} />
          </Link>
        </div>
      </div>
      )
    }
  };
  return (
    <div>
      {viewComponents(location.state)}
    </div>
    
  );
}

export default App;
