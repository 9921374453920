// import logo from './logo.svg';
// import './App.css';


function App() {
    return (
      <div>
        <img src="assets/img/footer_bg.png" style={{width:'90%'}} alt="aboutTab bg"/>
      </div>
    );
  }
  
  export default App;
  