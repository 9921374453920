import React from "react";
import "../css/deninePage.css";

function App() {
  return (
    <div className="denine-main-div">
      <img src="assets/img/denine.png" style={{width:'100%'}} alt=""/>
    </div>
  );
}

export default App;
